// Generated by Framer (ab692b1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["fN6d15S9r", "FAnLMgvH2"];

const serializationHash = "framer-yY0W8"

const variantClassNames = {FAnLMgvH2: "framer-v-10wtu5k", fN6d15S9r: "framer-v-y6sawv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "fN6d15S9r", "Variant 2": "FAnLMgvH2"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "fN6d15S9r"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "fN6d15S9r", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-y6sawv", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fN6d15S9r"} ref={refBinding} style={{backgroundColor: "var(--token-10f2fb8a-d082-42f4-bd26-c9c49d6724c4, rgb(250, 198, 31))", borderBottomLeftRadius: 150, borderBottomRightRadius: 150, borderTopLeftRadius: 150, borderTopRightRadius: 150, ...style}} {...addPropertyOverrides({FAnLMgvH2: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FCQyBHaW50byBOb3JtYWwgUmVndWxhcg==", "--framer-font-family": "\"ABC Ginto Normal Regular\", \"ABC Ginto Normal Regular Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-letter-spacing": "0.32em", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31)))", "--framer-text-transform": "uppercase"}}>Play</motion.p></React.Fragment>} className={"framer-nh9gb0"} fonts={["CUSTOM;ABC Ginto Normal Regular"]} layoutDependency={layoutDependency} layoutId={"skkdceJ1S"} style={{"--extracted-r6o4lv": "var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yY0W8.framer-pskrtd, .framer-yY0W8 .framer-pskrtd { display: block; }", ".framer-yY0W8.framer-y6sawv { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 8px 20px 8px 20px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-yY0W8 .framer-nh9gb0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-yY0W8.framer-y6sawv { gap: 0px; } .framer-yY0W8.framer-y6sawv > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-yY0W8.framer-y6sawv > :first-child { margin-left: 0px; } .framer-yY0W8.framer-y6sawv > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 84
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"FAnLMgvH2":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrYZD7KrMH: React.ComponentType<Props> = withCSS(Component, css, "framer-yY0W8") as typeof Component;
export default FramerrYZD7KrMH;

FramerrYZD7KrMH.displayName = "cursor-play 2";

FramerrYZD7KrMH.defaultProps = {height: 40, width: 84};

addPropertyControls(FramerrYZD7KrMH, {variant: {options: ["fN6d15S9r", "FAnLMgvH2"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerrYZD7KrMH, [{explicitInter: true, fonts: [{family: "ABC Ginto Normal Regular", source: "custom", url: "https://framerusercontent.com/assets/Zo2iDvx3OzYE7u7BjTe2l2dHYI8.woff2"}]}], {supportsExplicitInterCodegen: true})